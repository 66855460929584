.invisible {
  display: none;
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100vh;
  width: 100vw;
}

.map-wrapper {
  height: calc(100vh - 120px);
}

#pointr-map {
  height: calc(100vh - 120px) !important;
}

#pointr-navigation-description {
  display: none;
}

#pointr-navigation-title {
  margin-bottom: 16px;
}

#pointr-search {
  width: 60vw;
}

#exit-button {
  max-width: 25vw;
}

#navigation-button {
  white-space: nowrap;
}

#pointr-logo {
  opacity: 0;
}

#pointr-ui #pointr-navigation #pointr-navigation-steps-wrapper {
  display: none !important;
}

#pointr-ui #pointr-navigation #pointr-step-change {
  margin-top: 0;
}
