.pillbox-category__label {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.pillbox-category__pills {
  display: flex;
}

.pillbox-category__pill-wrapper {
  margin-right: 0.5rem;
}
