.menu-item-button {
  margin-top: 24px;
  background-color: var(--color-background-translucent-light);
  backdrop-filter: blur(4px);
  border-radius: 4px;
  padding: 24px 32px 24px 0;
  border: 1px solid var(--color-background-tertiary);
}

.menu-item-info {
  margin-top: 40px;
  background-color: var(--color-background-translucent-dark);
  backdrop-filter: blur(4px);
  border-radius: 4px;
  padding: 24px 0;
}

.menu-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-item-icon-wrapper {
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-icon {
  height: 40px;
}

.menu-item-text {
  display: flex;
  flex-direction: column;
}

.menu-item-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.menu-item-subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.menu-item-chevron-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
