.place__venue {
  display: flex;
  align-items: center;
}

.place__venue p {
  flex: 1;
  margin: 8px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-tertiary);
}

.venue-text {
  margin-right: 1rem;
}
