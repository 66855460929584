.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
}

.no-results-title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
}

.no-results-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
