.text {
  color: var(--color-text-primary);
  font-family: AmazonEmber;
}

.text-large {
  font-size: 2rem;
}

.text-medium {
  font-size: 1.2rem;
}

.text-label {
  color: var(--color-text-secondary);
  text-transform: capitalize;
}
