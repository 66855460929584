.pill {
  background-color: var(--color-text-secondary);
  height: 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 9px 16px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pill__active {
  background-color: var(--color-divider);
}

.pill-label {
  font-size: 16px;
  color: var(--color-text-button);
}

.pill__active .pill-label {
  font-size: 16px;
  color: var(--color-text-primary);
}

.pill__tall {
  padding: 19px 16px 21px;
}

.pill__directions {
  height: 20px;
  background-color: var(--color-background-button);
  margin-left: 40px;
}
