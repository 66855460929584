.modal {
  position: absolute;
  left: 0;
  right: 0;
}
.countdown-modal {
  position: absolute;
  background-color: var(--color-modal-overlay);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding-top: calc(50vh - 188px);
  align-items: center;
  z-index: 2001;
}

.display-none {
  display: none;
}

.countdown-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-background-primary-light);
  padding: 40px 60px;
  border-radius: 4px;
}

.countdown-content .icon {
  position: absolute;
  top: 6px;
  right: 6px;
  height: 25px;
  width: 25px;
}

.countdown-content .title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.countdown-content .subtitle {
  font-family: AmazonEmberThin;
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
}

.countdown-wrapper {
  margin-top: 16px;
  height: 88px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-secondary-lighter);
  border-radius: 100%;
}

.countdown {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.countdown-modal-footer {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.countdown-modal-footer-text {
  margin-right: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
