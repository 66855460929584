.past-session-container {
  padding: 24px;
}

.past-session-container .type {
  color: var(--color-primary-past-session-title);
  display: block;
  font-family: AmazonEmber;
  font-size: 16px;
  font-weight: 700;
}

.past-session-container .title {
  color: var(--color-primary-past-session-text);
  display: block;
  font-family: AmazonEmber;
  font-size: 18px;
  font-weight: 700;
}

.past-session-container .session-date-text {
  color: var(--color-primary-past-session-text);
}

.past-session-container .text {
  text-transform: none;
}
