.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-container .title {
  color: var(--color-switch-title-primary);
  font-size: 16px;
  font-weight: 400;
  font-family: AmazonEmber;
  margin: 0 16px;
}

.switch-container [type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.switch-container .switch label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch-container .switch span {
  position: relative;
  width: 48px;
  height: 24px;
  border-radius: 50px;
  background: var(--color-switch-title-primary);
  transition: all 0.3s;
}

.switch-container .switch span::before,
.switch-container .switch span::after {
  content: "";
  position: absolute;
}

.switch-container .switch span::before {
  left: 4px;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  background: var(--color-switch-disable-toggle);
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switch-container .switch span::after {
  top: 50%;
  transform: translateY(-50%);
}

.switch-container .switch [type="checkbox"]:checked + label span {
  background: var(--color-switch-enable-primary);
}

.switch-container .switch [type="checkbox"]:checked + label span::before {
  transform: translateX(24px);
  background: var(--color-switch-enable-toggle);
}

.switch-container .switch [type="checkbox"]:checked + label span::after {
  width: 24px;
  height: 24px;
  left: 4px;
  background-image: url(../../../assets/svg/check.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
