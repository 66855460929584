.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 40px 80px;
  height: 40px;
}

.header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-right {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: var(--color-text-primary);
}

.header-left .home-button {
  margin-right: 24px;
  height: 40px;
}

.header-left .header-logo {
  margin-right: 24px;
  height: 24px;
  width: 109px;
}

.header-right .header-date {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.header-right .header-time {
  margin-left: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.header-right .search-button {
  margin-left: 24px;
}
