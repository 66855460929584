@font-face {
  font-family: "AmazonEmber";
  src: url("./AmazonEmber-Regular.woff2") format("woff2"),
    url("AmazonEmber-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AmazonEmberThin";
  src: url("./AmazonEmber-Thin.woff2") format("woff2"),
    url("AmazonEmber-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AmazonEmberItalic";
  src: url("./AmazonEmber-Italic.woff2") format("woff2"),
    url("AmazonEmber-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AmazonEmberBold";
  src: url("./AmazonEmber-Bold.woff2") format("woff2"),
    url("AmazonEmber-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AmazonEmberBoldItalic";
  src: url("./AmazonEmber-BoldItalic.woff2") format("woff2"),
    url("AmazonEmber-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
