.catalog-header {
  background-color: transparent;
  padding: 0 80px;
}

.catalog-header-title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.catalog-header__search-filter {
  display: flex;
  padding: 24px 0;
}

.catalog-header__search-bar {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.catalog-header__search-filter .search-button {
  margin-left: 16px;
}
