@import "./fonts/fonts.css";

* {
  --color-background-primary: black;
  --color-background-primary-light: #0b0c0c;
  --color-background-secondary: #252829;
  --color-background-secondary-lighter: #485050;
  --color-background-tertiary: white;
  --color-background-button: #949c9c;
  --color-background-translucent-light: #ffffff40;
  --color-background-translucent-dark: #0b0c0c40;
  --color-border-color: #485050;
  --color-divider: #707070;
  --color-modal-overlay: #00000080;
  --color-text-primary: white;
  --color-text-secondary: #32c8ff;
  --color-text-tertiary: #e7e9e9;
  --color-text-button: #232f3e;
  --color-accent: #3c46ff;

  /* Switch */
  --color-switch-title-primary: #fff;
  --color-switch-enable-primary: #c9a8ff;
  --color-switch-enable-toggle: #232f3e;
  --color-switch-disable-toggle: #6c7778;

  /* Past Session */
  --color-primary-past-session-title: #c9a8ff;
  --color-primary-past-session-text: #949c9c;
}

html {
  background-color: var(--color-background-primary);
  font-size: min(max(1rem, 3vw), 42px);
}

body,
#root {
  margin: 0;
  position: fixed;
  overflow: hidden;
}
