.invisible {
  display: none;
}

.catalog-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  visibility: visible;
}

.catalogContent {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reverseContent {
  flex-direction: column-reverse;
}
