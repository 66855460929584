.accordion-toggle {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.accordion-toggle .react-icon {
  height: 1rem;
  width: 1rem;
}
