.invisible {
  display: none;
}

.intro-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intro-video-wrapper {
  z-index: -10;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.1);
}

.intro-menu {
  display: flex;
  flex-direction: column;
  margin: 80px;
}

.intro-menu .event-name {
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
}

.intro-menu .menu-title {
  font-size: 76px;
  line-height: 96px;
  font-weight: 300;
  margin-bottom: 16px;
}

.bold {
  font-weight: 700;
}
