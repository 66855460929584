.input {
  color: var(--color-text-button);
  font-family: AmazonEmber !important;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 6px 0 12px;
}

.input::placeholder {
  color: var(--color-text-button);
}

.input:focus {
  outline: 0.3vh solid transparent;
  outline-offset: -0.1vh;
}

.input-wrapper {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.input-clear {
  cursor: pointer;
  position: absolute;
  right: 8px;
  height: 20px;
  width: 20px;
}
