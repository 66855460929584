.catalog-list {
  height: 100%;
  overflow-y: scroll;
  padding: 0 16px;
}

.catalog-list::-webkit-scrollbar-track {
  border: 0.6vh solid transparent;
  background-clip: padding-box;
  background-color: var(--color-divider);
}

.catalog-list::-webkit-scrollbar {
  width: 8px;
}

.catalog-list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--color-text-secondary);
}

.catalog-list-show-more {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding-bottom: 80px;
}
