.session-date {
  height: 24px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.session-date-text {
  margin-right: 16px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-tertiary);
}

.session-abstract {
  font-size: 16px;
  line-height: 24px;
}
