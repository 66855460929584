.catalog-list-item {
  background-color: var(--color-background-secondary);
  border: 1px solid var(--color-divider);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(11, 12, 12, 0.16);
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 16px;
}

.catalog-list-item-main {
  display: flex;
}

.catalog-list-item-section {
  margin-right: 40px;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.catalog-list-item-section.text-label {
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.catalog-list-item-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.catalog-list-item-buttons {
  position: absolute;
  right: 0;
  top: -4px;
}

.catalog-list-item-summary {
  width: 100%;
}

.catalog-list-item-aside p {
  flex: 1;
  margin: 16px 0 0 0;
  font-size: 16px;
  line-height: 24px;
}
